<template>
  <div>
    <v-banner class="lavBanner" single-line height="400">
      <v-img src="https://h5.ophyer.cn/official_website/banner/bannerShootingService400.jpg"
          height="100%"
          :transition="false"
          @load="handleLoad"
          class="d-flex align-center"
          :class="imgLoad ? 'v-image__image--animation':''">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <!--<v-container color="transparent" class="d-flex justify-center">
          <v-card color="transparent" flat width="80%">
            <div class="d-flex flex-no-wrap justify-center" style="color:#FFF">
              <div>
                <v-card-title class="white--text font-size-banner-title justify-center">
                  为有VR需求企业
                </v-card-title>
                <v-card-title class="white--text font-size-banner-title">
                  提供拍摄、制作、后期一体化服务
                </v-card-title>
                <v-card-title class="white--text font-size-banner-tip justify-center">
                  给您极致完美的视觉体验 让品牌传播有声有色
                </v-card-title>
              </div>
            </div>
          </v-card>
        </v-container>-->
      </v-img>
    </v-banner>
     <!-- class="d-flex justify-center" -->
    <v-container style="max-width:1200px">
      <v-card class="warp warp2" :flat="true">
        <div class="public-title" style="margin:80px 0 20px 0">
          <p>SERVICE INTRODUCTION</p>
          <div>
            <span>产品功能介绍</span>
            <span>利用多维感知、全局洞察、实时决策解决复杂局面下企业难题</span>
          </div>
        </div>
        <v-tabs v-model="tab" centered  class="g-tabs">
          <v-tab v-for="(item,index) in data.items" :key="index">
            <v-card-title class="font-size-20" :style="{'color':tab == index ? '#0084ff!important':''}">{{ item.tab }}</v-card-title>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item,index) in data.items" :key="index" :transition="false">
              <div class="box">
                <transition name="slide-fade" appear>
                  <div class="right px-5" v-show="tab === index">
                    <v-card-title class="title px-0 py-1" v-text="item.title" />
                    <v-card-text class="tip" v-text="item.content" />
                  </div>
                </transition>
                <transition name="slide-fade-reverse" appear>
                  <v-img v-show="tab === index" :src="item.src" width="400" height="260" class="rounded-xl">
                    <template v-slot:placeholder>
                          <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                          >
                            <v-progress-circular
                                indeterminate
                                size="80"
                                width="8"
                                color="grey lighten-3"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                  </v-img>
                </transition>
              </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
    <!--<v-container style="max-width:1200px">-->
    <!--  <div class="public-title" style="margin:42px 0 10px 0">-->
    <!--      <p>COOPERATION CASES</p>-->
    <!--      <div>-->
    <!--        <span>合作案例</span>-->
    <!--      </div>-->
    <!--  </div>-->
    <!--  <v-row class="justify-center">-->
    <!--    <v-col v-for="(card,index) in anli" :key="index" cols="3">-->
    <!--      <v-card-->
    <!--              class="mx-auto item-card"-->
    <!--              max-width="240"-->
    <!--              max-height="410"-->
    <!--              @click="goCaseDetail(card)"-->
    <!--            >-->
    <!--              <v-img-->
    <!--                max-height="340"-->
    <!--                :src="card.coverImg"-->
    <!--                :aspect-ratio="9/16"-->
    <!--              >-->
    <!--                <template v-slot:placeholder>-->
    <!--                  <v-row class="fill-height ma-0" align="center" justify="center">-->
    <!--                    <v-progress-circular-->
    <!--                      indeterminate-->
    <!--                      size="80"-->
    <!--                      color="red lighten-5"-->
    <!--                    ></v-progress-circular>-->
    <!--                  </v-row>-->
    <!--                </template>-->
    <!--              </v-img>-->
    <!--              <v-card-title class="font-size-14 font-weight-bold sizeStyle" v-text="card.itemTitle"></v-card-title>-->
    <!--            </v-card>-->
    <!--    </v-col>-->
    <!--  </v-row>-->
    <!--</v-container>-->
    <v-container>
      <div class="public-title" style="margin-top:70px;margin-bottom:-10px">
          <p>SOLUTIONS</p>
          <div>
            <span>解决方案</span>
          </div>
      </div>
      <v-row class="mt-5 max-width-1200" style="margin:0 auto">
        <v-col cols="4" v-for="(card,index) in data.peitaoList" :key="index" class="d-flex child-flex">
            <div class="box-img rounded-lg" :style="{backgroundImage: 'url('+card.src+')'}">
              <div>{{ card.title }}</div>
            </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container style="max-width:1200px">
      <div class="public-title" style="margin-top:90px">
          <p>OUR CHARACTERISTICS</p>
          <div>
            <span>我们的特点</span>
          </div>
        </div>
        <v-row class="justify-center">
          <v-col cols="3" v-for="(card,index) in data.cardList" :key="index">
                <v-card
                    height="100%"
                    class="cardStyle" elevation="0">
                  <div class="d-flex justify-center">
                    <v-img
                        :src="card.src"
                        class="white--text align-end flex-grow-0"
                        height="100"
                        width="100"
                    >
                    <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        size="80"
                        color="red lighten-5"
                      ></v-progress-circular>
                    </v-row>
            </template>
                    </v-img>
                  </div>
                  <v-card-title class="justify-center font-size-24">{{card.title}}</v-card-title>
                  <v-card-text class="font-size-14 text-align-center" v-text="card.content"></v-card-text>
                </v-card>
              </v-col>
        </v-row>
    </v-container>
    <v-container class="max-width-1200 mb-16">
      <div class="public-title" style="margin-rop:45px">
          <p>COOPERATION PROCESS</p>
          <div>
            <span>合作流程</span>
          </div>
      </div>
      <div>
        <v-img :src="data.cooperation" contain></v-img>
      </div>
    </v-container>
  </div>
</template>

<script>
let _this;
import textData from '@/static/textData.js' // 合作案例
import lavData from '@/static/lavData.js'
import qs from 'qs'
export default {
  data: () => ({
    imgLoad: false,
    windowHight: window.innerHeight/2,
    data:lavData.ShootingService,
    anli: [textData.anli.wangshangchufang,textData.anli.meilizhongguo,textData.anli.quanjingqiche,textData.anli.baicaiershouche],
    tab: null,
  }),
  mounted(){
    _this = this;
    this.bannerLoad = true;
  },
methods: {
    handleLoad: ()=>{
      _this.imgLoad = true;
      console.log("ewf")
    },
    goCaseDetail(item){
      let query = {
        source: 'qita',
        anli: item.self
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
}
};
</script>

<style lang="scss" scoped>
.lavBanner {
  border: none !important;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .bannerContent {
    height: 100%;
    display: flex;
    color: #ffff;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
  }
}
.warp2 {
  .box {
    width: 1000px;
    margin: 50px auto;
    display: flex;
    align-items: center;
  }
  .left {
    margin-right: 80px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
    }
  }
  .right{
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    margin-right: 110px;
    .v-card__text {
      padding: 0;
      line-height: 30px!important;
    }
    .title {
      font-family: Source Han Sans CN;
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .tip {
      margin: 12px 0 35px;
      font-size: 16px !important;
      color:#666!important;
      opacity:1;
    }
    .text {
      font-size: 14px;
      line-height: 23px;
    }
  }
}
::v-deep .v-card__text{
  line-height: 20px!important;
}
.item-card {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0px 1px 14px 0px rgb(0 60 255 / 8%) !important;
  border-radius: 8px;
  transition: 0.3s;
  &:hover {
    transform: scale(1.04);
    box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
  }
  &::after {
      content: "";
      background-image: url("./../../assets/images/home/case-icon.png");
      background-size: 100%;
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 20px;
      right: 10px;
  }
}
.sizeStyle{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box-img {
      width: 240px;
      height: 160px;
      background-size: cover;
      overflow: hidden;
      transition: all 0.3s ease-in;
      div {
        width: 100%;
        height: 100%;
        background-color:rgba(25, 26, 27, 0.5);
        color: #fff;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
</style>